<template lang="pug">
.customers
    v-card(light, flat)
        v-card-title Customers
        v-card-subtitle
            v-text-field(
                v-model="search"
                prepend-inner-icon="search"
                label="Search"
                single-line
                hide-details
                clearable, clear-icon='clear'
                color="brand",
                outlined, dense, light, solo, rounded, flat
            )
    v-data-table(
        :headers="headers"
        :items="customers"
        :search="search"
        sort-by="name"
        @click:row='viewCustomerData',
        light,
        fixed-header,
        disable-pagination,
        hide-default-footer,
        height='80vh'
    )

        template(v-slot:top)
            v-toolbar(flat)
                v-spacer
                v-btn.mr-2(color="blue" dark @click="getCustomers" small) Refresh
                v-dialog(v-model="newCustomerDialog" max-width="1000px" light)
                    template(v-slot:activator="{on, attrs}")
                        v-btn(color="brand" dark v-bind="attrs" v-on="on" small) New Customer
                    v-card
                        v-card-title
                            span.text-h5 {{formTitle}}
                        v-card-text()
                            v-container
                                v-form(ref='newCustomerForm' v-model="newCustomerFormValid" lazy-validation @submit.prevent)
                                    v-row
                                        v-col(cols="5")
                                            v-text-field(
                                                label="Customer",
                                                v-model="editedItem.name"
                                                outlined, color='brand'
                                                :rules="[rules.required]"
                                                :error-messages="duplicateNameMessage"
                                            )
                                        v-col(cols="3")
                                            v-select(
                                                label='State'
                                                v-model.trim='editedItem.state'
                                                outlined, color='brand', light
                                                :items="states"
                                                item-text="name"
                                                item-value="value",
                                                :rules="[rules.required]"
                                            )
                                        v-col
                                            v-spacer
                                    v-row
                                        v-col(cols="12" sm="6" md="4")
                                            v-checkbox(v-model="editedItem.isPublic" label="Is Public", outlined, color='brand', dense)
                                        v-col(cols="12" sm="6" md="4")
                                            v-checkbox(v-model="editedItem.hasInternet" label="Has Internet", outlined, color='brand', dense)
                                        v-col(cols="12" sm="6" md="4")
                                            v-checkbox(v-model="editedItem.hasAnalytics" label="Advanced Analytics", outlined, color='brand', dense)
                                        v-col(cols="12" sm="6" md="4")
                                            v-checkbox(v-model="editedItem.doCloudBackup" label="Cloud Backups", outlined, color='brand', dense)
                                    v-row
                                        v-col(v-if="editedItem.isPublic" cols="6" sm="6" md="6")
                                            v-text-field(
                                                v-model="editedItem.domainUrl"
                                                label="Domain URL (include https://)",
                                                outlined, color='brand',
                                                :rules="[rules.required, rules.noSpaces, rules.startsWithHttps]"
                                            )
                                        v-col
                                            v-text-field(
                                                label="Designation"
                                                v-model="editedItem.customerDesignation"
                                                :rules="[rules.required, rules.noSpaces]"
                                                :error-messages="duplicateDesignationMessage"
                                                color='brand'
                                                outlined
                                            )
                                        v-col
                                            v-select(
                                                v-model='editedItem.customersConfig.systemSoftware',
                                                :items='systemSoftwareTypes',
                                                :rules="[rules.required]"
                                                item-text='name', item-value='type',
                                                label='System Software', outlined, dense, color='brand')
                                        v-col
                                            v-spacer
                        v-card-actions
                            v-spacer
                            v-btn(color="blue darken-1" text @click="closeNewCustomerDialog") Cancel
                            v-btn(color="blue darken-1" @click="validateNewCustomer" :disabled="!newCustomerFormValid") Save

        template(v-slot:item.users="{item}")
            span {{ item.customersUsers.length }}
        template(v-slot:item.keys="{item}")
            span {{ (item && item.keys && item.keys.length) || 0}}
        template(v-slot:item.machines='{item}')
            span {{ item.customersMachines.length }}
        template(v-slot:item.comm='{item}')
            span {{ item.customersComms.length }}
        template(v-slot:item.credentials='{item}')
            span {{ item.customersCredentials.length }}
        template(v-slot:item.contacts='{item}')
            span {{ item.customersContacts.length }}

    v-bottom-sheet(v-model='editCustomerDialog', fullscreen, persistent, light, scrollable)
        v-card(light)
            v-card-title Edit Customer: {{ editedItem.name }}
            v-card-text
                v-tabs(v-model='currentTab', color='brand', show-arrows)
                    v-tab Basic Info
                    v-tab Portal Users
                    v-tab Notification Settings
                    v-tab Hardware
                    v-tab Credentials
                    v-tab Keys
                    v-tab Contacts
                v-tabs-items(v-model='currentTab', light)
                    v-tab-item
                        v-container
                            v-row
                                v-col
                                    .text-h5 Basic Info
                            v-row
                                v-col(cols="12" sm="6" md="4")
                                    v-text-field(v-model="editedItem.name" label="Customer", outlined, dense, color='brand')
                                v-col(cols="12" sm="6" md="4")
                                    v-text-field(v-model="editedItem.state" label="State", outlined, dense, color='brand')
                                v-col(cols='12', ms='6', md='4')
                                    v-text-field(v-model='editedItem.customersConfig.ein', label='EIN (Tax ID)', outlined, dense, color='brand')
                            v-row
                                v-col(cols="12" sm="6" md="4")
                                    v-checkbox(v-model="editedItem.customersConfig.isPublic" label="Is Public", dense, color='brand')
                                v-col(cols="12" sm="6" md="4")
                                    v-checkbox(v-model="editedItem.customersConfig.hasInternet" label="Has Internet", dense, color='brand')
                                v-col(cols="12" sm="6" md="4")
                                    v-checkbox(
                                        v-model="editedItem.customersConfig.hasAnalytics"
                                        label="Advanced Analytics",
                                        dense,
                                        color='brand'
                                    )
                                v-col(cols="12" sm="6" md="4")
                                    v-checkbox(v-model="editedItem.customersConfig.doCloudBackup" label="Cloud Backup", dense, color='brand')
                            v-row
                                v-col(v-if="editedItem.customersConfig.isPublic")
                                    v-text-field(
                                        v-model="editedItem.customersConfig.domainUrl"
                                        label="Domain URL (include https://)",
                                        dense, outlined, color='brand'
                                        :rules="[rules.required, rules.startsWithHttps, rules.noSpaces]")
                                v-col
                                    v-text-field(
                                        v-model="editedItem.customersConfig.branchName"
                                        label="Designation (used in branch name)"
                                        disabled, outlined, dense
                                    )
                                v-col
                                    v-select(
                                        v-model='editedItem.customersConfig.systemSoftware',
                                        :items='systemSoftwareTypes',
                                        item-text='name', item-value='type',
                                        label='System Software', outlined, dense, color='brand'
                                    )
                                v-col
                                    v-spacer
                            v-row
                                v-col
                                    .text-h5 Site Configuration
                            v-row
                                v-col
                                    v-select(v-model='editedItem.customersConfig.remoteAccessType',
                                        :items='remoteAccessTypes',
                                        item-text='name',
                                        item-value='type',
                                        label='Remote Access Type',
                                        outlined, dense, color='brand')
                                v-col

                                    credentialsManager(
                                        v-if='editedItem.customersConfig.remoteAccessType === "vpn"'
                                        :customerId="editedItem.id"
                                        :credentialTypes="credentialTypes.filter(cred => cred.value ==='vpn')"
                                        :credentials="editedItem.customersCredentials.filter(cred => !['comm','machine'].includes(cred.value))"
                                        :jiggle="jiggle"
                                        :filterTypes=['vpn']
                                        rightLabel="VPN"
                                        @deleteCredential="removeCredential"
                                        @updateCredential="onUpdateCredential"
                                        @addCredential="onAddCredential"
                                    )
                                    v-spacer(v-else)

                    v-tab-item
                        v-container
                            UserManagement(
                                allowUserListEdit,
                                :customerId='editedItem.id',
                                @existingUserAdded='existingUserAdded',
                                @disassociateUser='removeUserFromCustomer',
                                @newUserAdded='newUserAdded'
                            )

                    v-tab-item
                        v-container
                            v-row
                                v-col
                                    .text-h5 Notifications
                                        v-btn.white--text(@click='showAPIKeys = !showAPIKeys', icon, :title='showAPIKeys ? "Hide sensitive information" : "Show sensitive information"', color='brand')
                                            v-icon {{ showAPIKeys ? 'visibility_off' : 'visibility' }}
                            v-row
                                v-col
                                    v-text-field(v-model='editedItem.customersConfig.twilioSID', :type='showAPIKeys ? "text" : "password"', label='Twilio SID', outlined, dense, append-icon='content_copy', @click:append='copyToClipboard(editedItem.customersConfig.twilioSID)', color='brand')
                                v-col
                                    v-text-field(v-model='editedItem.customersConfig.twilioAuthToken', :type='showAPIKeys ? "text" : "password"', label='Twilio Auth Token', outlined, dense, append-icon='content_copy', @click:append='copyToClipboard(editedItem.customersConfig.twilioAuthToken)', color='brand')
                                v-col
                                    v-text-field(
                                        label='Twilio Phone 1'
                                        v-model='editedItem.customersConfig.twilioNumber1'
                                        :rules="[rules.required, rules.numbersOnly, rules.tenDigits]"
                                        append-icon='content_copy',
                                        @click:append='copyToClipboard(editedItem.customersConfig.twilioNumber1)'
                                        outlined, dense, color='brand'
                                    )
                                v-col
                                    v-text-field(
                                        label='Twilio Phone 2 (optional)'
                                        v-model='editedItem.customersConfig.twilioNumber2'
                                        :rules="[rules.numbersOnlyOptional, rules.tenDigitsOptional]"
                                        append-icon='content_copy',
                                        @click:append='copyToClipboard(editedItem.customersConfig.twilioNumber2)'
                                        outlined, dense, color='brand'
                                    )
                            v-row
                                v-col
                                    v-text-field(v-model='editedItem.customersConfig.sparkPostAPIKey', :type='showAPIKeys ? "text" : "password"', label='Sparkpost API Key', outlined, dense, append-icon='content_copy', @click:append='copyToClipboard(editedItem.customersConfig.sparkPostAPIKey)', color='brand')
                                v-col
                                    v-text-field(v-model='editedItem.customersConfig.sparkPostURL', label='Sparkpost URL', outlined, dense, append-icon='content_copy', @click:append='copyToClipboard(editedItem.customersConfig.sparkPostURL)', color='brand')


                    v-tab-item
                        //- Tab Content for Hardware
                        v-container
                            v-row
                                v-container
                                    v-row
                                        v-col
                                            commsManager(
                                                :customerId="editedItem.id"
                                                :credentials="editedItem.customersCredentials.filter(cred => cred.type === 'comm')"
                                                :devices="editedItem.customersComms"
                                                :updateComms='updateDevice'
                                                :jiggle="jiggle"
                                            )
                                    v-row
                                        v-col
                                            machinesManager(
                                                :customerId="editedItem.id"
                                                :credentials="editedItem.customersCredentials.filter(cred => cred.type === 'machine')"
                                                :devices="editedItem.customersMachines"
                                                :updateMachines='updateDevice'
                                                :jiggle="jiggle"
                                            )
                    v-tab-item
                        //- Tab content for Credentials
                        v-container
                            v-row
                                v-col
                                    credentialsManager(
                                        :customerId="editedItem.id"
                                        :credentialTypes="credentialTypes",
                                        :credentials="editedItem.customersCredentials",
                                        :commDevices="editedItem.customersComms",
                                        :machines="editedItem.customersMachines",
                                        :jiggle="jiggle",
                                        @deleteCredential="removeCredential"
                                        @updateCredential="onUpdateCredential"
                                        @addCredential="onAddCredential"
                                    )
                    v-tab-item
                        //- Tab content for Keys
                        v-container
                            KeysTable(
                                :customer="editedItem.id"
                                :keyList="editedItem.keys"
                                @key:refreshed="refreshKeys",
                            )
                    v-tab-item
                        //- Tab content for Contacts
                        v-container
                            contactsManager(
                                :customerId="editedItem.id",
                                :contacts="editedItem.customersContacts",
                                :jiggle="jiggle",
                                :states="states",
                                @deleteContact="onRemoveContact"
                                @updateContact="onUpdateContact"
                                @addContact="onAddContact"
                            )

            v-card-actions
                v-spacer
                v-btn(color='orange darken-1', text, @click='deleteDialog = true') Delete Customer
                v-btn(color='blue darken-1', text, @click='editCustomerDialog = false') Cancel
                v-btn(color='blue darken-1', text, @click='saveCustomer' :disabled="!editCustomerIsValid") Save

        v-overlay(absolute, :value='busy')
            .text-h6 Please Wait...
            v-progress-linear(indeterminate)

        v-dialog(v-model='deleteDialog', max-width='500px')
            v-card
                v-card-title Delete Customer
                v-card-text
                    p Are you sure you want to delete this customer?
                v-card-actions
                    v-spacer
                    v-btn(text, @click='deleteDialog=false') Cancel
                    v-btn(color='red', @click='deleteCustomer') Delete

    v-snackbar(v-model='showAlert', dark, :timeout='8000') {{ message }}


</template>

<script lang="js">

import _ from 'lodash';
import dti from 'dti';
import ipRegex from 'ip-regex';
import KeysTable from '@/components/devops/KeysTable';
import User from './user';
import ipaddress from '@/components/common/ipaddress';
import comip from '@/components/common/comip';
import datepicker from '@/components/common/datepicker';
import commsManager from '@/components/common/commsManager';
import machinesManager from '@/components/common/machinesManager';
import credentialsManager from '@/components/common/credentialsManager';
import UserManagement from '@/components/portal/userManagement';
import contactsManager from '@/components/common/contactsManager';

const DUPLICATE_MESSAGE = 'Already Exists!';

export default {
    name: "DevopsCustomers",
    components: {
        KeysTable,
        User,
        ipaddress,
        datepicker,
        comip,
        commsManager,
        machinesManager,
        credentialsManager,
        UserManagement,
        contactsManager
    },
    data () {
        return {
            duplicateDesignationMessage: '',
            duplicateNameMessage:'',
            newCustomerFormValid: false,
            vpnLabel: 'VPN',
            vpnType: ['vpn'],
            search: '',
            counter: 0,
            dti,
            accessKey: 'devopscustomers',
            headers: [
                {text: 'Customer', value: 'name'},
                {text: 'State', value: 'state'},
                {text: 'Users', value: 'users', sortable: false},
                {text: 'Keys', value: 'keys', sortable: true},
                {text: 'Machines', value: 'machines', sortable: false},
                {text: 'Comm Devices', value: 'comm', sortable: false},
                {text: 'Credentials', value: 'credentials', sortable: false},
                {text: 'Contacts', value: 'contacts', sortable: false},
            ],
            /** @type {CustomerType[]} */
            customers: [],
            allUsers: [],
            formTitle: 'New Customer',
            editedIndex: -1,
            editedItem: {customersConfig:{}, customersUsers: [], customersComms: [],  customersMachines: [], customersCredentials: [], customersContacts: []},
            defaultCustomer: {
                id: -1,
                name: '',
                state: '',
                isPublic: false,
                hasInternet: false,
                hasAnalytics: false,
                doCloudBackup: false,
                domainUrl: '',
                customerDesignation: '',
                users: [],
                customersConfig: {},
                customersUsers: [],
                customersCredentials: [],
                customersComms: [],
                customersMachines: [],
                customersContacts: []
            },
            defaultUser: {
                id: -1, firstName: '', lastName: '', username:'', email:'', password: '', phoneNumber: '', customerId: -1, addedBy: 'dorsett'
            },
            currentTab: 0,
            defaultCredential: {
                type: '',
                accountType: null,
                username: '',
                password: '',
                domain: '',
                notes: ''
            },
            defaultContact: {
                type: '',
                nameLast: '',
                nameFirst: '',
                email: '',
                phoneCell: '',
                phoneLand: '',
                streetAddress: '',
                city: '',
                state: '',
                postalCode: '',
                notes: '',
                customerId: -1
            },
            newCustomerDialog: false,
            deleteDialog: false,
            editCustomerDialog: false,
            keysDialog: false,
            message: '',
            customer: 0,
            showAPIKeys: false,
            rules: {
                tenDigits: value => (!!value && value.length === 10) || '10 digit phone',
                tenDigitsOptional: value => {
                    if(!value) return true;
                    return (value.length === 10 || '10 digit phone');
                },
                numbersOnly: value => (!!value && /^\d*$/.test(value)) || 'Only numbers',
                numbersOnlyOptional: value => {
                    if(!value ) return true;
                    return (!!value && /^\d*$/.test(value)) || 'Only numbers';
                },
                required: value => !!value || 'Required.',
                noSpaces: value => !/\s/.test(value) || 'No spaces allowed.',
                ipRules: value => ipRegex({exact: true}).test(value) || 'Invalid IP Address',
                password: value => !value || /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&£*\-_+=[\]{}|\\:',?/`~"();!]|\.(?!@)){8,}$/.test(value) || 'Invalid Password.',
                startsWithHttps: value => value.startsWith('https://') || 'Must start with https://',
            },
            remoteAccessTypes: [
                {
                    type: 'none',
                    name: 'None'
                },
                {
                    type: 'splashtop unattended',
                    name: 'Unattended Splashtop'
                },
                {
                    type: 'splashtop sos',
                    name: 'Splashtop SOS'
                },
                {
                    type: 'vpn',
                    name: 'VPN'
                }
            ],
            systemSoftwareTypes: [
                {
                    type: "InfoScan",
                    name: "InfoScan"
                },
                {
                    type: "Other",
                    name: "Other"
                }
            ],
            machineTypes: [
                {
                    name: 'Server',
                    value: 'server'
                },
                {
                    name: 'Workstation',
                    value: 'workstation'
                }
            ],
            serverDesignations: [
                {
                    name: 'Primary',
                    value: 'primary',
                },
                {
                    name: 'Secondary',
                    value: 'secondary',
                },
                {
                    name: 'Tertiary',
                    value: 'tertiary',
                },
                {
                    name: 'Other',
                    value: 'other'
                }
            ],
            credentialTypes: [
                {
                    name: 'Machine',
                    value: 'machine'
                },
                {
                    name: 'Cloud Backup',
                    value: 'cloud_backup'
                },
                {
                    name: 'VPN',
                    value: 'vpn'
                },
                {
                    name: 'Comm Device',
                    value: 'comm'
                },
                {
                    name: 'Forticloud',
                    value: 'forticloud'
                }
            ],
            states: [
                {value: "AL", name: "Alabama"},
                {value: "AK", name: "Alaska"},
                {value: "AZ", name: "Arizona"},
                {value: "AR", name: "Arkansas"},
                {value: "CA", name: "California"},
                {value: "CO", name: "Colorado"},
                {value: "CT", name: "Connecticut"},
                {value: "DE", name: "Delaware"},
                {value: "DC", name: "Dist of Columbia"},
                {value: "FL", name: "Florida"},
                {value: "GA", name: "Georgia"},
                {value: "HI", name: "Hawaii"},
                {value: "ID", name: "Idaho"},
                {value: "IL", name: "Illinois"},
                {value: "IN", name: "Indiana"},
                {value: "IA", name: "Iowa"},
                {value: "KS", name: "Kansas"},
                {value: "KY", name: "Kentucky"},
                {value: "LA", name: "Louisiana"},
                {value: "ME", name: "Maine"},
                {value: "MD", name: "Maryland"},
                {value: "MA", name: "Massachusetts"},
                {value: "MI", name: "Michigan"},
                {value: "MN", name: "Minnesota"},
                {value: "MS", name: "Mississippi"},
                {value: "MO", name: "Missouri"},
                {value: "MT", name: "Montana"},
                {value: "NE", name: "Nebraska"},
                {value: "NV", name: "Nevada"},
                {value: "NH", name: "New Hampshire"},
                {value: "NJ", name: "New Jersey"},
                {value: "NM", name: "New Mexico"},
                {value: "NY", name: "New York"},
                {value: "NC", name: "North Carolina"},
                {value: "ND", name: "North Dakota"},
                {value: "OH", name: "Ohio"},
                {value: "OK", name: "Oklahoma"},
                {value: "OR", name: "Oregon"},
                {value: "PA", name: "Pennsylvania"},
                {value: "PR", name: "Puerto Rico"},
                {value: "RI", name: "Rhode Island"},
                {value: "SC", name: "South Carolina"},
                {value: "SD", name: "South Dakota"},
                {value: "TN", name: "Tennessee"},
                {value: "TX", name: "Texas"},
                {value: "UT", name: "Utah"},
                {value: "VT", name: "Vermont"},
                {value: "VA", name: "Virginia"},
                {value: "WA", name: "Washington"},
                {value: "WV", name: "West Virginia"},
                {value: "WI", name: "Wisconsin"},
                {value: "WY", name: "Wyoming"}
            ],
            showServerDatePicker: false,
            showCommDatePicker: false,
            jiggle: false,
            editCustomerIsValid: false,
        };
    },
    methods: {
        // customers
        /**
         * Closes the new customer dialog.
         */
        closeNewCustomerDialog () {
            this.newCustomerDialog = false;
            this.editCustomerDialog = false;

            this.duplicateDesignationMessage = null;
            this.duplicateNameMessage = null;

            if (this.$refs.newCustomerForm) {
                this.$refs.newCustomerForm.reset();
                this.newCustomerFormValid = false;
            }
            this.$nextTick(() => {
                this.editedItem = _.cloneDeep(this.defaultCustomer);
                this.editedIndex = -1;
            });
        },

        /**
         * Validate new customer
         *
         */
        async validateNewCustomer () {
            try{
                const {error, duplicate, name, designation} = await this.sendCommand({
                    action: 'validateNewCustomer',
                    parameters: this.editedItem
                });

                if(error){
                    this.message = `Error saving message do to '${error}'`;
                    this.closeNewCustomerDialog();
                }
                else if(duplicate){
                    // mark error on the form itself
                    if(designation){
                        this.duplicateDesignationMessage = DUPLICATE_MESSAGE;
                    }
                    if(name){
                        this.duplicateNameMessage = DUPLICATE_MESSAGE;
                    }
                    // wait a bit and then remove error messages so that rules will activate;
                    setTimeout(() => {
                        this.duplicateDesignationMessage = '';
                        this.duplicateNameMessage = '';
                    }, 2000);
                } else {
                    return this.saveCustomer();
                }
                //this.saveCustomer()
            } catch(err){
                this.message = `Error saving customer`;
            }
        },

        /**
         * Saves the current customer that is being edited.
         */
        async saveCustomer () {
            const customer = this.editedItem;
            try{
                const result = await this.sendCommand({
                    action: 'saveCustomer',
                    parameters: customer
                });
                if (result.error) {
                    this.message = `Error saving customer`;
                } else {
                    this.message = `Customer saved`;
                }
                this.getCustomers();
                this.getUsers();
            } catch(err){
                this.message = `Save customer request aborted`;
            } finally {
                this.closeNewCustomerDialog();
            }
        },

        /*
        * Soft deletes the customer that is being edited
        */
        async deleteCustomer () {
            const customer = this.editedItem;
            try{
                const result = await this.sendCommand({
                    action: 'deleteCustomer',
                    parameters: customer
                });
                if (result) {
                    this.message = 'Customer deleted';
                } else {
                    this.message = 'Error deleting customer';
                }
            } catch(err){
                this.message = `Delete customer request aborted ${err.message}`;
            } finally {
                // Close the customer edit dialog and refresh the customer data
                this.closeNewCustomerDialog();
                this.getCustomers();
                this.getUsers();
            }
        },

        /**
         * Pulls key information from server for the specified customer.
         *
         * @param {Number} customerId
         */
        async refreshKeys (customerId){
            try {
                const params = {customerId};
                const {keys} = (await this.axios.post('/devopsapi/customers/getActiveKeys', params)).data;
                this.customers.find(customer => customer.id === customerId).keys = keys || [];
                this.editedItem.keys = keys || [];
            } catch(e){
                console.log("refreshKeys Error", e);
            }
        },

        // Users
        /**
         * Edits the selected user
         *
         * @param {Object} customer
         */
        viewCustomerData (customer) {
            this.editedItem = _.cloneDeep(customer);
            this.$set(this.editedItem, 'newUsers', []);
            this.editCustomerDialog = true;
            this.editedIndex = this.customers.indexOf(customer);
        },

        /**
         * Requests all data from the server
         */
        getAllData () {
            this.getCustomers();
            this.getUsers();
        },

        /**
         * Requests customer data from the server
         */
        getCustomers () {
            const includeViews = ['users', 'config', 'keys', 'machines', 'comm', 'credentials', 'contacts'];
            this.socketEmit('getCustomers', includeViews, (results = []) => {
                this.customers = results;
            });
        },

        /**
         * Requests user data from the server
         */
        getUsers () {
            this.socketEmit('getAllUsers', null, (results) => {
                this.allUsers = results;
            });
        },

        /**
         * Removes an _existing_ user from the customer.
         */
        async removeUserFromCustomer (userId) {
            const itemToRemove = this.editedItem.customersUsers.find(item => item.id === userId);
            const index = this.editedItem.customersUsers.indexOf(itemToRemove);
            if (index > -1) {
                this.editedItem.customersUsers.splice(index, 1);
            }
        },

        /**
         * Copies the specified text to the clipboard.
         *
         * @param {String} text
         */
        copyToClipboard (text) {
            navigator.clipboard.writeText(text);
            this.message = 'Copied to clipboard';
        },


        /**
         * Deletes a hardware device from the customer.
         *
         * @param {Object} device - comm or machine to delete
         * @param {string|number} device.id
         * @param {string} itemType - comm | machine
         */
        removeDevice (device, itemType) {
            console.group('customers: removeDevice', device.id, device.type);

            const propTableName = itemType === 'comm' ? 'customersComms' : 'customersMachines';
            const propColumnName = itemType === 'comm' ? 'customersCommId' : 'customersMachineId';

            const index = this.editedItem[propTableName].findIndex(m => m.id === device.id);
            if(index > -1){
                this.editedItem[propTableName].splice(index, 1);
                // deleting any associated credentials
                const credentialsToDelete = this.editedItem.customersCredentials.filter(credential => credential[propColumnName] === device.id);

                credentialsToDelete.forEach(c => {
                    this.editedItem.customersCredentials.findIndex(credential => credential.id === c.id);
                    if(index > -1){
                        console.log("Deleting credential", c.id, c.username, c.type);
                        this.editedItem.customersCredentials[index].deleted = true;
                        this.editedItem.customersCredentials.splice(index, 1);
                    }
                });
            }
            console.groupEnd();
        },

        toggleJiggle () {
            this.jiggle = !this.jiggle;
        },

        /**
         * Adds a new contact object to the current customer being edited.
         * @param {String} targetType
         * @param {String} targetId
         */
        onAddContact (contact) {
            this.editedItem.customersContacts.push(contact);
            this.toggleJiggle();
        },

        /**
         * Adds a new credential object to the current customer being edited.
         * @param {String} targetType
         * @param {String} targetId
         */
        addCredential (targetType, targetId) {
            console.log("customers: addCredential");
            if (typeof targetType !== 'string') {
                targetType = '';
            }
            const newCredential = _.cloneDeep(this.defaultCredential);
            newCredential.type = targetType;
            newCredential.customerId = this.editedItem.id;
            switch (targetType) {
            case 'machine':
                newCredential.customersMachineId = targetId;
                newCredential.accountType = 'local';
                break;
            case 'comm':
                newCredential.customersCommId = targetId;
                break;
            }
            newCredential.add = true;
            newCredential.id = dti.makeuuid();
            this.editedItem.customersCredentials.push(newCredential);
            this.toggleJiggle();
        },

        /**
         * Responds to credentialsManager event
         * @param {Credential} credential
         */
        onAddCredential (credential){
            console.log('customers: onAddCredential', credential.id);
            this.editedItem.customersCredentials.push(credential);
            this.toggleJiggle();
        },

        onUpdateCredential (credential){
            console.log('customers: onUpdateCredential', credential.id);
            const index = this.editedItem.customersCredentials.findIndex(c => c.id === credential.id);
            if(index > -1 ){
                this.editedItem.customersCredentials[index] = credential;
                this.toggleJiggle();
            }
            console.log('edited credential is', this.editedItem.customersCredentials[index] );
        },

        onUpdateContact (contact) {
            const index = this.editedItem.customersContacts.findIndex(c => c.id === contact.id);
            if(index > -1 ){
                this.$set(this.editedItem.customersContacts, index, contact);
                this.toggleJiggle();
            }
        },

        /**
         * Deletes a credential from the customer.
         *
         * @param {Object} credential - Credential to delete
         * @param {Object} credential.id - unique id
         */
        removeCredential (credential) {
            const index = this.editedItem.customersCredentials.findIndex(c => c.id === credential.id);
            if(index > -1 ){
                this.editedItem.customersCredentials.splice(index, 1);
                this.toggleJiggle();
            }
        },

        onRemoveContact (contact) {
            const index = this.editedItem.customersContacts.findIndex(c => c.id === contact.id);
            if(index > -1 ){
                this.$delete(this.editedItem.customersContacts, index);
            }
        },

        /**
         * determine differences between ItemCredentials and currentCredentials
         * @param {Credential[]} ItemCredentials
         * @param {string} deviceType - 'comm' | 'machine'
         */
        manageCredentials (ItemCredentials, deviceType){

            console.group('manageCredentials', deviceType);

            const currentCredentials = this.editedItem.customersCredentials.filter(c => c.type === deviceType);
            const newCredentials = ItemCredentials.filter(c => c.add);
            const updatedCredentials = ItemCredentials.filter(c => c.edit);

            const deletedCredentials = currentCredentials.filter(cc => !ItemCredentials.find(c => c.id === cc.id ));

            newCredentials.forEach(newCredential => {
                console.log(' -- adding new credential', newCredential.id, newCredential.username);
                delete newCredential.add;
                delete newCredential.edit;
                const currentCredential = currentCredentials.find(cc => cc.id === newCredential.id);
                if(!currentCredential){
                    this.editedItem.customersCredentials.push(newCredential);
                }
            });

            // this works because we are editing the original object
            updatedCredentials.forEach(c => {
                const currentCredential = currentCredentials.find(cc => cc.id === c.id);
                if (currentCredential){
                    console.log(' -- editing existing credential', c.id, c.username);
                    currentCredential.customersCommId = c.customersCommId;
                    currentCredential.customersMachineId = c.customersMachineId;
                    currentCredential.username = c.username;
                    currentCredential.password = c.password;
                }
            });

            deletedCredentials.forEach(c => {
                const index = this.editedItem.customersCredentials.findIndex(cc => cc.id === c.id);
                if(index > -1){
                    console.log(' -- deleting credential', c.id, c.username);
                    this.editedItem.customersCredentials.splice(index, 1);
                }
            });

            console.groupEnd();

        },

        /**
         * update a single comm or machine
         *
         * @param {object} item - comm or machine
         * @param {Credential[]} ItemCredentials
         * @param {'comm'|'machine'} itemType -
         */
        updateDevice (item, ItemCredentials, itemType){
            console.group('customers: updateDevice', item.id, itemType);
            // will replace the comm device within the comm devices array
            const propName = itemType === 'comm' ? 'customersComms' : 'customersMachines';

            if(item.add){
                console.log(' -- adding new device', item.id);
                delete item.add;
                delete item.edit;
                this.editedItem[propName].push(item);
                this.manageCredentials(ItemCredentials, itemType);
            } else if(item.edit){
                console.log(' -- editing device', item.id);
                // device
                delete item.edit;
                const foundDeviceIndex = this.editedItem[propName].findIndex(c => c.id === item.id);
                if(foundDeviceIndex > -1){
                    this.editedItem[propName][foundDeviceIndex] = item;
                }
                this.manageCredentials(ItemCredentials, itemType);
            } else {
                console.log(' -- remove device', item.id);
                this.removeDevice(item, itemType);
            }

            this.toggleJiggle();
            console.groupEnd();
        },
        existingUserAdded (user) {
            this.editedItem.customersUsers.push(user);
        },
        newUserAdded (user) {
            this.editedItem.customersUsers.push(user);
        }

    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            },
        },
        credentialsList () {
            console.group('credentialsList fired');
            const credentials = [];
            console.log('credentialsList length before', this.editedItem.customersCredentials.length);
            this.editedItem.customersCredentials.forEach(credential => {
                const credClone = _.cloneDeep(credential);
                credClone.description = this.getCredentialDescription(credClone);
                credentials.push(credClone);
            });
            console.log('credentialsList length after', credentials.length);
            console.groupEnd();
            return credentials;
        }

    },
    provide () {
        return {
            "copyToClipboard": this.copyToClipboard
        };
    },
    watch: {
        newCustomerDialog (dialogOpen) {
            if (dialogOpen) {
                // when the dialog is open, make sure to clear any existing data.
                this.editedItem = Object.assign({}, this.defaultCustomer);
                this.$nextTick( () => {
                    this.newCustomerFormValid = this.$refs.newCustomerForm && this.$refs.newCustomerForm.validate();
                });
            }
        },
        editedItem: {
            handler (item) {
                if(item.isPublic && item.domainUrl === ''){
                    this.newCustomerFormValid = false;
                }
                this.editCustomerIsValid = !(item.customersConfig.isPublic && item.customersConfig.domainUrl === '');
                this.$nextTick( () => {
                    this.newCustomerFormValid = this.$refs.newCustomerForm && this.$refs.newCustomerForm.validate();
                });

            },
            deep: true
        },
    },
    mounted () {
        this.editedItem = Object.assign({}, this.defaultCustomer);
        this.getAllData();
        window.customersvm = this;
    },

    /**
     * Update the device
     */
    onChange (contact) {
        this.newCustomerFormValid = this.$refs["newCustomerForm"].validate();
    },

    /**
     * @typedef {object} Credential
     * @property {string} type
     * @property {number} customerId
     * @property {number=} customersCommId
     * @property {number=} customersMachineId
     * @property {string} username
     * @property {string} password
     * @property {string=} domain
     * @property {string=} notes
     * @property {string} accountType
     * @property {boolean=} add
     * @property {boolean=} edit
     */
};


</script>

<style lang="scss" scoped>
.editButtons {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
}

.keysHeader {
    margin-right: 5px
}
</style>

<style lang="scss">
.notesEditor {
    button {
        background-color: white;
    }

    .trix-button-group--file-tools {
        display: none;
    }
}

.v-data-table {
    tr :hover {
        cursor: pointer;
    }
}
</style>
