<template lang="pug">
.keysList
    v-card
        v-card-subtitle Keys changes occur in real-time
        v-card-text
            v-simple-table(fixed-header, light)
                template(v-slot:default)
                    thead
                        tr
                            th Key
                            th Type
                            th Content
                            th Created
                            th Actions
                    tbody
                        tr(v-for="key in keyList" :key="key.id")
                            td {{ key.id }}
                            td {{ key.keyType }}
                            td
                                v-icon(
                                    small,
                                    class="mr-2",
                                    @click="copyToClipboard(key.keyContent)"
                                ) content_copy
                                span {{ key.keyContent }}
                            td {{ key.createdAt | niceDateFromUtc }}
                            td
                                v-tooltip(right)
                                    template(v-slot:activator="{ on, attrs }")
                                        v-btn(
                                            color="error",
                                            @click="deleteKey(key.id)"
                                            v-bind="attrs"
                                            v-on="on"
                                        ) Delete
                                    span Soft Delete
        v-card-actions
            v-menu(offset-y)
                template(v-slot:activator="{ on, attrs }")
                    v-btn(
                        color="brand",
                        v-bind="attrs"
                        v-on="on"
                        class="white--text"
                    ) Add
                v-list
                    v-list-item(
                        v-for="keyType in keyTypes"
                        :key="keyType"
                        @click="createKey(keyType)"
                    )
                        v-list-item-title {{ keyType }}
    v-snackbar(
        v-model="snackbar"
        :timeout="timeout"
        :color="color"
        :multi-line="multiLine"
    )
        span(:style="{ color: textColor }") {{ message }}
        template(v-slot:action="{ attrs }")
            v-btn(text v-bind="attrs" @click="snackbar = false") Close
</template>

<script>
import { DateTime } from "luxon";

export default {
    emits: ["key:refreshed"],
    props: {
        customer: Number,
        refresh: Function,
        keyList: Array,
    },
    data () {
        return {
            keyTypes: ["infoscanArchive", "dccsAPI", "mongoAuth", "analytics"],
            // snackbar
            alertMessage: '',
            snackbar: false,
            message: "",
            color: "brand",
            textColor: "#ffffff",
            timeout: 3000,
            multiLine: false,
        };
    },
    methods: {
        async deleteKey (keyId) {
            const params = { keyId };
            try {
                const { success, error } = await this.apiCall("/devopsapi/customers/deleteKey", { keyId });
                if (success) {
                    this.$emit("key:refreshed", this.customer);
                    this.showSnackbar({ message: "Key Deleted", type: "success" });
                } else {
                    this.showSnackbar({ message: `Deletion Failed: ${error.message}`, type: "error" });
                }
            } catch (e) {
                this.message = `Deletion Failed: ${e}`;
            }
        },

        async createKey (keyType) {
            try {
                const { success, error } = await this.apiCall("/devopsapi/customers/createKey", {
                    type: keyType,
                    customerId: this.customer,
                });
                if (success) {
                    this.$emit("key:refreshed", this.customer);
                    this.showSnackbar({ message: "Key Created", type: "success" });
                } else {
                    this.showSnackbar({ message: "Failed to create key", type: "error" });
                }
            } catch (err) {
                this.showSnackbar({ message: "Key creation failed", type: "error" });
            }
        },

        async apiCall (url, params) {
            return (await this.axios.post(url, params)).data;
        },

        copyToClipboard (content) {
            navigator.clipboard.writeText(content).then(() => {
                this.message = "Content copied to clipboard!";
                this.showSnackbar({ message: "Content copied to clipboard!", type: "success" });
            }).catch(err => {
                this.showSnackbar({ message: "Failed to copy content", type: "error" });
            });
        },

        /**
         *
         * @param {object} options
         * @param {string} options.message - The message to display in the snackbar
         * @param {string} options.color - default is red
         * @param {number} options.timeout - default is 3000 milliseconds
         */
        showSnackbar ({message, color = "brand", textColor = "#ffffff", timeout = 3000, type = null}) {
            if (type === 'warning') {
                color = 'orange';
                textColor = '#000000';
            } else if (type === 'error') {
                color = '#8B0000';
                textColor = '#ffffff';
            }
            this.message = message;
            this.color = color;
            this.textColor = textColor;
            this.timeout = timeout;
            this.snackbar = true;
        },
    },
    filters: {
        niceDateFromUtc (value) {
            if (!value) return "";
            return DateTime.fromISO(String(value))
                .setZone("America/New_York")
                .toLocaleString(DateTime.DATETIME_MED);
        },
    },
    mounted() {
        window.keystablevm = this;
    },
};
</script>
